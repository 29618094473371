input.search {
  width: 100%;
  font-size: 2em;
  margin: 1em auto;
}

table {
  /* border: 1px solid #ccc; */
  width: 95%;
}

table th {
  text-align: left;
}

tr.ingeschreven{
  background-color: goldenrod;
}

.opleidingrij{
  height:40px;
}

.opleidingrij td select, .opleidingrij td input{
  width: 80%;
}

.opslaan{
  background-color:goldenrod;
  text-decoration: none;
  color:white;
  padding:5px;
  border-radius: 20px;
}

.nieuwerichting button{
  background-color:darkolivegreen;
}

.nieuwerichting tr:first-child td:last-child button{
  display: none;
}

.verwijderen{
  background-color:firebrick;
  text-decoration: none;
  color:white;
  padding:5px;
  border-radius: 20px;
}

.siteheader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:60px;
}

.navigatie{
  text-align: left;
  flex:1;
}

.navigatie a, .afmelden button{
  background-color: goldenrod ;
  padding: 10px;
  border-radius: 20px;
  color:white;
  text-decoration: none;
  border:none;
}

.navigatie a:hover, .afmelden button:hover{
  background-color: rgba(218,165,32,50%);
  
}

.afmelden{
  text-align: right;
  flex:1;
}
