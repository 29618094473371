label {
  display: block;
  margin: 1em;
}

label span.label {
  display: block;
  line-height: 2em;
}

label span.error {
  display: inline-block;
  vertical-align: top;
  color: #f00;
  margin-top: 0.25em;
  float: none;
}

label input,
label textarea {
  vertical-align: top;
  width: 100%;
  padding: 10px 0 10px 10px;
}
label input[type="checkbox"] {
  width: auto;
}

[name="lecoOpmerkingen"] {
  height: 15em;
}

fieldset {
  margin-bottom: 3em;
  border: none;
  padding: 0;
  border: 5px solid #ccc;
}

fieldset > legend {
  font-size: 1.5em;
  padding: 0.5em;
  margin: auto;
  text-transform: uppercase;
}

fieldset > fieldset {
  margin-bottom: auto;
  border-left: none;
  border-right: none;
  width: auto;
  border-width: 2px;
  background-color: #eee;
}

fieldset > fieldset legend {
  font-size: 1.1em;
}

button[type="submit"] {
  font-size: 2em;
  padding: 5px 15px;
  float: right;
  margin-bottom: 1em;
  clear: both;
}

.error {
  color: #f00;
  float: right;
}

div.info {
  margin: 1em;
  border: 1px solid #f8db76;
  background-color: #fdfbd5;
  padding: 0.5em 1em;
}

div.small{
  font-size: 0.8em;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-row {
  display: flex;
  gap: 20px;
  align-items: center;
}



.form-info {
  flex: 1;
}

.itemMargin, .opleiding {
  margin: 1em;
}

.selectBox{
  display: flex;
}

.selectBox select, .opleiding select {
  width: auto;
  height: 3em;
  margin-left: 1em;
}

.opleiding select{
  font-weight: bold;
  min-width: 200px;
}

.selectBox > span{
  margin-top: 0.5em;
  white-space: nowrap;
}

.selectBox > label {
  margin: 0 0 0 1em;
  flex: 1;
}
